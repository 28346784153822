import { Card } from 'primereact/card';
import { Dropdown } from 'primereact/dropdown';
import React, { useEffect, useRef, useState } from 'react';
import { IParcel } from '../../models/IParcel';
import { Calendar } from 'primereact/calendar';
import { InputText } from 'primereact/inputtext';
import { confirmDialog } from 'primereact/confirmdialog';
import { Toast } from "primereact/toast";
import { Button } from 'primereact/button';

export default function RelatedCertificates(props: any) {
    const [selectedParcel, setSelectedParcel] = useState(props.selectedParcel);
    const [selectedParcelId, setSelectedParcelId] = useState(props.selectedParcel?.parcelID);





    useEffect(() => {
        setSelectedParcel(props.selectedParcel);
    }, [props.selectedParcel]);



    return (
        <React.Fragment>
            {(selectedParcel?.relatedTaxCertificates.length ?? 0) > 0 && (
                <React.Fragment>
                    <div className="p-grid">
                        <div className="p-col">
                            <Card title={`Related Tax Certificates`}>
                                {props.selectedParcel?.relatedTaxCertificates.map((item: any) => {
                                    return (
                                        <React.Fragment>
                                            <Button
                                                className={`p-mr-2  ${item.status == "Redeemed" ? "p-button-success" : "p-button-outlined"}`}
                                                label={`${item.taxSaleNo} - ${item.status}`}
                                                icon="pi pi-reply"
                                                onClick={() => {
                                                    setSelectedParcelId(item.parcelID);
                                                    props.getParcel(item.parcelID);
                                                    props.history.push(`/parcels/${item.parcelID}/payoffrequest`);
                                                }}
                                            />
                                        </React.Fragment>
                                    );
                                })}
                            </Card>
                        </div>
                    </div>
                    <br />
                </React.Fragment>
            )}
        </React.Fragment>
    )
}